footer {
  margin-top: -100px;
  padding-top: 40px;
  background-color: $primary-purple;

  p,
  span {
    color: $white;
    font-size: 14px;
    text-align: center;
  }

  .upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    /deep/ a {
      text-decoration: underline;
      color: $white;
    }

    .icon-background {
      background-color: #73246b;
      border-radius: 5px;
      margin-right: 15px;
      padding: 10px;
      display: inline-block;

      .email {
        width: 30px;
        height: 22px;
      }

      .phone {
        width: 27px;
        height: 22px;
      }
    }

    .upper-bottom {
      width: 70%;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;

      a {
        text-decoration: none;
      }
    }
  }

  .bottom {
    margin-top: 20px;
    border-top: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 75px 30px 50px;
    gap: 40px;

    p {
      max-width: 1000px;
      line-height: 1.48;
      text-align: justify;
    }

    .here {
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .upper {
    padding: 0 40px;
  }

  .bottom {
    flex-direction: column;
    align-items: center !important;
    padding: 40px 20px !important;

    img {
      height: 96px;
    }

    p {
      font-size: 0.75rem;
      padding: 0 20px 0 20px !important;
    }
  }
}

@media (max-width: 400px) {
  .upper {
    padding: 0 30px;
  }

  .bottom {
    flex-direction: column;
    padding: 40px 0 !important;

    p {
      padding: 40px 30px 0 30px !important;
    }
  }
}